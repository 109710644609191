import { useTranslation } from '@dreamstack/i18n'
import { SimpleInput } from '@dreamstack/simple-components'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TailwindConfig from '../../../../tailwind.config'

const AutocompleteChipsStyled = withStyles({
  groupLabel: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#2b2d36',
  },
  tag: {
    backgroundColor: 'rgba(43, 45, 54, 0.1)',
    height: 40,
    'border-radius': 0,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: '#2b2d36',
    },
    '& span': {
      fontSize: '16px',

      fontWeight: 600,
    },
  },
  endAdornment: {
    top: 'auto',
    right: '12px',
  },
  clearIndicator: {
    color: TailwindConfig.theme.extend.colors.accentroGray.full,
  },
})(Autocomplete)

const inputClasses = {
  input: {
    style: {
      fontSize: '24px',
      fontWeight: 600,
      padding: '8px',
    },
  },
}

export type Option<T = any> = {
  value: T
  optionLabel: string
  optionDisplayedQuantity?: number | null
  optionGroupLabel?: string | null
}

export type GroupedAutocompleteProps = {
  placeholder?: string
  options: Option[]
  selectedOptions: (Option | string)[]
  setSelectedOptions: (options: (Option | string)[]) => void
  uiVariant?: 'filled' | 'standard'
}

export const GroupedAutocomplete: (
  props: GroupedAutocompleteProps
) => JSX.Element = (props: GroupedAutocompleteProps) => {
  const t = useTranslation()

  return (
    <AutocompleteChipsStyled
      multiple
      freeSolo
      loading={!props.options.length}
      id="property-search-autocomplete"
      options={props.options}
      groupBy={(option: unknown) => (option as Option).optionGroupLabel ?? ''}
      getOptionLabel={(option: unknown | string) =>
        typeof option === 'string' ? option : (option as Option).optionLabel
      }
      tw="w-full "
      clearText={t('accentro:autocomplete.clearText')}
      renderInput={(params) => (
        <SimpleInput
          variant={props.uiVariant ?? 'standard'}
          {...params}
          label={!props.selectedOptions.length ? props.placeholder : ''}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            ...inputClasses.input,
          }}
        />
      )}
      value={props.selectedOptions}
      onChange={(event, options: unknown) => {
        props.setSelectedOptions(options as Option[])
      }}
      renderOption={(option: unknown, { inputValue }) => {
        return (
          <div>
            <span
              style={{
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {(option as Option).optionLabel}
            </span>
            {(option as Option).optionDisplayedQuantity && (
              <span tw="text-accentroAqua-full">
                &nbsp;({(option as Option).optionDisplayedQuantity})
              </span>
            )}
          </div>
        )
      }}
    />
  )
}
