import { Switch } from '@headlessui/react'
import type { TwStyle } from 'twin.macro'
import tw from 'twin.macro'

const Bold = tw.span`font-semibold`

type SimpleSwitchProps = {
  checked: boolean
  setChecked: (checked: boolean) => void
  label: string
  description?: string
  grayDescription?: boolean
  disabled?: boolean
  nonBoldLabel?: boolean
  labelCss?: TwStyle[]
}

export const SimpleSwitch = (props: SimpleSwitchProps) => {
  const {
    checked,
    setChecked,
    disabled,
    label,
    nonBoldLabel,
    labelCss,
    description,
  } = props
  return (
    <Switch.Group>
      <div tw="flex gap-x-px-20 items-center">
        <Switch
          checked={checked}
          onChange={setChecked}
          disabled={disabled}
          css={[
            tw`bg-accentroGray-300 relative inline-flex h-[24px] w-[43px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`,
            props.checked && tw`bg-accentroLightGreen-full`,
          ]}
        >
          <span
            css={[
              tw`translate-x-0 pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`,
              props.checked && tw`translate-x-[19px]`,
            ]}
          />
        </Switch>
        <div>
          {nonBoldLabel ? (
            <Switch.Label css={labelCss} className="mr-4">
              {label}
            </Switch.Label>
          ) : (
            <Bold>
              <Switch.Label className="mr-4">{label}</Switch.Label>
            </Bold>
          )}
          {description && (
            <Switch.Description
              css={[props.grayDescription && tw`text-accentroGray-500`]}
            >
              {description}
            </Switch.Description>
          )}
        </div>
      </div>
    </Switch.Group>
  )
}
