import { useIsLoggedInWithLoading } from '@dreamstack/auth'
import { useEffect } from 'react'
import { useAuthModalContext } from '../components/AuthModalContext'

export const useForcedLogin = () => {
  const { isLoggedIn, loading } = useIsLoggedInWithLoading()
  const { setOpen, open } = useAuthModalContext()

  useEffect(() => {
    const shouldBeOpen = !isLoggedIn
    if (shouldBeOpen !== open && !loading) {
      setOpen(shouldBeOpen)
    }
  }, [isLoggedIn, loading, open, setOpen])

  return isLoggedIn
}
