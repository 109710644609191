import type { RegionCityLocationOption } from '@dreamstack/accentro-contentful/src/blocks/CustomBlocks/FindYourProperties'
import {
  getKey,
  getValue,
  RegionCityLocationAutocomplete,
} from '@dreamstack/accentro-contentful/src/blocks/CustomBlocks/FindYourProperties'
import { useTranslation } from '@dreamstack/i18n'
import { SimpleRadio } from '@dreamstack/simple-components'
import { trpc } from '@dreamstack/trpc/client'
import { useState } from 'react'
import 'twin.macro'
import type { PropertySearchFilterValues, RentedTypes } from '../..'
import {
  defaultFilterValues,
  MaxFilterValue,
  useGetPropstackSavedQueryFromFilter,
  useRadioOptionsRented,
} from '../..'
import { FilterWithSliderTemplate } from '../../property/filter/PropertyFilterWithSliderTemplate'
import type { ScreenProps } from '../ModalTypes'
import OnboardingLayout from '../OnboardingLayout'

export const SearchProfile = ({
  open,
  handleClose,
  nextStep,
  steps,
  setPropertySearchFilterValues,
}: ScreenProps & {
  setPropertySearchFilterValues: (
    propertySearchFilterValues: PropertySearchFilterValues
  ) => void
}) => {
  const t = useTranslation()
  const [subStep, setSubStep] = useState<number>(0)
  const { mutateAsync: upsertSavedQuery } =
    trpc.savedQueries.upsertSavedQuery.useMutation()

  const transformFilterToPropstackSavedQuery =
    useGetPropstackSavedQueryFromFilter()
  const radioOptionsRented = useRadioOptionsRented()
  const subSteps = 2

  const [selectedOptions, setSelectedOptions] = useState<
    (RegionCityLocationOption | string)[]
  >([])
  const [roomRange, setRoomRange] = useState<[number, number]>([
    0,
    MaxFilterValue.roomRange,
  ])
  const [livingspace, setLivingspace] = useState<[number, number]>([
    0,
    MaxFilterValue.livingSpace,
  ])
  const [priceRange, setPriceRange] = useState<[number, number]>([
    0,
    MaxFilterValue.price,
  ])
  const [rented, setRented] = useState<RentedTypes>('both')

  const isFinalStep = () => subStep === subSteps

  const nextSubStep = () => {
    if (subStep + 1 > subSteps) {
      nextStep()
    } else {
      setSubStep(subStep + 1)
    }
  }

  const handleSubmit = () => {
    if (isFinalStep()) {
      const citiesLocationsRegions = {
        cities: [] as string[],
        locations: [] as string[],
        regions: [] as string[],
      }
      for (const selected of selectedOptions) {
        const value = getValue(selected)
        if (value) citiesLocationsRegions[getKey(selected)].push(value)
      }

      const propertySearchFilterValues = {
        ...defaultFilterValues,
        ...citiesLocationsRegions,
        roomRange,
        livingspace,
        priceRange,
        rented,
      }
      setPropertySearchFilterValues(propertySearchFilterValues)

      const queryVars = transformFilterToPropstackSavedQuery({
        filter: propertySearchFilterValues,
      })
      upsertSavedQuery({ query: queryVars })
      nextStep()
    } else {
      nextSubStep()
    }
  }

  return (
    <OnboardingLayout
      canGoBack
      title={t('accentro:onboardingModal:searchProfile:title')}
      imageSrc="/static/images/onboarding/smiling_man.jpg"
      buttons={{
        primary: {
          text: isFinalStep()
            ? t('accentro:onboardingModal:searchProfile:create')
            : t('accentro:onboardingModal:next'),
          onClick: handleSubmit,
        },
        secondary:
          subStep === 0
            ? {
                text: t('accentro:onboardingModal:skip'),
                onClick: nextStep,
              }
            : undefined,
      }}
      open={open}
      handleClose={handleClose}
      steps={steps}
    >
      <>
        <span>{t('accentro:onboardingModal:searchProfile:text')}</span>
        {subStep === 0 ? (
          <RegionCityLocationAutocomplete
            placeholder={t(
              'accentro:onboardingModal:searchProfile:locationPlaceholder'
            )}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        ) : subStep === 1 ? (
          <div tw="flex flex-col gap-px-32 md:gap-px-48 ">
            <div>
              <FilterWithSliderTemplate
                title={t('accentro:room.rooms')}
                value={roomRange}
                onChange={setRoomRange}
                min={0}
                max={MaxFilterValue.roomRange}
              />
            </div>
            <div>
              <FilterWithSliderTemplate
                title={t('accentro:living-space')}
                subTitle={`in ${t('accentro:squareMeter')}`}
                value={livingspace}
                onChange={setLivingspace}
                min={0}
                max={MaxFilterValue.livingSpace}
                endAdornment={t('accentro:squareMeter')}
              />
            </div>
          </div>
        ) : (
          <>
            <div>
              <FilterWithSliderTemplate
                title={t('accentro:purchase-price')}
                subTitle={'in EUR'}
                value={priceRange}
                onChange={setPriceRange}
                min={0}
                max={MaxFilterValue.price}
                step={1000}
                valueLabelFormat={(value) => `${Math.round(value / 1000)}`}
                endAdornment="€"
              />
            </div>
            <div>
              <SimpleRadio
                radioGroupProps={{
                  value: rented,
                  onChange: (e, v) => setRented(v as RentedTypes),
                  defaultValue: 'both',
                  name: 'rented',
                  row: false,
                }}
                options={radioOptionsRented}
              />
            </div>
          </>
        )}
      </>
    </OnboardingLayout>
  )
}
