import { useTranslation } from '@dreamstack/i18n'
import 'twin.macro'
import type { ScreenProps } from '../ModalTypes'
import OnboardingLayout from './../OnboardingLayout'

export const Welcome = ({ open, handleClose, nextStep }: ScreenProps) => {
  const t = useTranslation()
  return (
    <OnboardingLayout
      title={t('accentro:onboardingModal:welcome:title')}
      subtitle={t('accentro:onboardingModal:welcome:subtitle')}
      imageSrc="/static/images/onboarding/room_open_door.png"
      buttons={{
        primary: {
          text: t('accentro:onboardingModal:welcome:go'),
          onClick: nextStep,
        },
      }}
      open={open}
      handleClose={handleClose}
    >
      <div>
        <span>{t('accentro:onboardingModal:welcome:text1')}</span>
        <br />
        <br />
        <span tw="font-semibold">
          {t('accentro:onboardingModal:welcome:text2')}
        </span>
      </div>
    </OnboardingLayout>
  )
}
