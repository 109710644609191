import { SimpleInput } from '@dreamstack/simple-components'
import Skeleton from '@material-ui/lab/Skeleton'
import tw from 'twin.macro'

const Bold = tw.span`font-semibold`
const Sub = tw.span``
const Gray = tw.span`text-accentroGray-500`

type MyProfileTextProps = {
  title: string
  value?: string | null
  placeholder?: string | null
  loading: boolean
}

export const MyProfileText = (props: MyProfileTextProps) => {
  return (
    <div tw="flex flex-col min-w-[200px]">
      <Bold>{props.title}</Bold>
      {props.loading ? (
        <Skeleton tw="w-full" variant="text" animation="wave"></Skeleton>
      ) : (
        <>
          {!props.value &&
            (props.placeholder ? (
              <Gray>{props.placeholder}</Gray>
            ) : (
              <span>-</span>
            ))}
          {props.value && <div tw="whitespace-pre-line">{props.value}</div>}
        </>
      )}
    </div>
  )
}

type MyProfileTextInputProps = MyProfileTextProps & {
  setValue: (value: string) => void
  name?: string | null
  autoFocus?: boolean | null
  type?: string | null
  errorText?: string | null
}

export const MyProfileTextInput = (props: MyProfileTextInputProps) => {
  return (
    <SimpleInput
      tw="w-full"
      label={props.title}
      size="small"
      value={props.value ?? ''}
      name={props.name ?? ''}
      autoFocus={props.autoFocus ?? false}
      onChange={(e) => props.setValue(e.target.value)}
      type={props.type ?? ''}
      error={!!props.errorText}
      helperText={props.errorText}
      disabled={props.loading}
    />
  )
}

export const MyProfileDescriptionText = (props: MyProfileTextProps) => {
  return (
    <div tw="flex flex-col gap-y-px-8">
      <Bold>{props.title}</Bold>
      {props.value && <div tw="whitespace-pre-line">{props.value}</div>}
    </div>
  )
}
