import { useTranslation } from '@dreamstack/i18n'
import { ModalCloseButton } from '@dreamstack/simple-components'
import { Dialog } from '@headlessui/react'
import Image from 'next/legacy/image'
import type { RefObject } from 'react'
import { useEffect, useRef, useState } from 'react'
import 'twin.macro'

type Props = {
  open: boolean
  handleClose: () => void
  title: string
  subtitle?: string
  steps?:
    | {
        step: number
        total: number
      }
    | string
  imageSrc: string
  children: React.ReactNode
}

export const DialogWithImage = ({
  open,
  handleClose,
  title,
  subtitle,
  imageSrc,
  children,
  steps,
}: Props) => {
  const t = useTranslation()
  const modalRef = useRef<HTMLDivElement>(null)
  const higherThanViewport = useHigherThanViewport({ ref: modalRef })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      tw="fixed z-20 overflow-y-auto flex w-full h-screen"
      style={
        higherThanViewport
          ? {
              top: 0,
              alignItems: 'start',
              justifyContent: 'center',
            }
          : {
              top: 0,
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }
      }
    >
      <Dialog.Overlay tw="fixed inset-0 backdrop-blur-sm bg-accentroGray-300" />
      <Dialog.Panel
        tw="relative bg-accentroWhite-full flex w-full max-w-[96vw] md:max-w-[784px] ml:max-w-[1020px] my-px-12"
        ref={modalRef}
      >
        <div tw="relative w-0 sm:w-[222px] ml:w-[340px]">
          <Image src={imageSrc} layout="fill" tw="object-cover" alt="fake" />
        </div>
        <div tw="flex-1 bg-white p-px-24 sm:p-px-40 ml:p-px-64">
          <ModalCloseButton handleClose={handleClose} />
          <div tw="flex flex-col gap-px-40 ml:gap-px-48">
            {/* Title */}
            <div tw="flex flex-col gap-px-8 text-accentroGray-full pt-px-24 sm:p-0">
              {!!steps && (
                <p tw="text-sm font-semibold">
                  {typeof steps === 'string'
                    ? steps
                    : t('accentro:onboardingModal.steps', { ...steps })}
                </p>
              )}
              <p tw="text-2xl md:text-3xl ml:text-4xl font-semibold">{title}</p>
              {!!subtitle && (
                <p tw="text-base ml:text-xl font-semibold">{subtitle}</p>
              )}
            </div>
            {children}
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}

const useHigherThanViewport = ({ ref }: { ref: RefObject<HTMLDivElement> }) => {
  const [biggerThanVP, setBiggerThanVP] = useState(false)

  useEffect(() => {
    const handleResize = () =>
      setBiggerThanVP(
        ref.current !== null && ref.current.clientHeight > window.innerHeight
      )
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [ref])

  return biggerThanVP
}
