import { useTranslation } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import { Link } from '@material-ui/core'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import 'twin.macro'
import type { PropertySearchFilterValues } from '../..'
import { useMyAccentroBaseUrl, usePropertyOverviewUrl } from '../..'
import { SimpleMuiButton } from '../../layout/SimpleMuiButton'
import type { ScreenProps } from '../ModalTypes'
import OnboardingLayout from './../OnboardingLayout'

export const Done = ({
  open,
  handleClose,
  propertySearchFilterValues,
}: ScreenProps & {
  propertySearchFilterValues?: PropertySearchFilterValues
}) => {
  const t = useTranslation()
  const myAccentroBaseUrl = useMyAccentroBaseUrl()
  const propertyOverviewUrl = usePropertyOverviewUrl()
  const router = useRouter()

  const { mutate: setOnboarded } = trpc.user.updateOnboarded.useMutation()
  useEffect(() => {
    setOnboarded({ onboarded: true })
  }, [setOnboarded])

  return (
    <OnboardingLayout
      title={t('accentro:onboardingModal:done:title')}
      imageSrc="/static/images/onboarding/woman_in_chair.png"
      open={open}
      handleClose={handleClose}
      steps={t('accentro:onboardingModal:done:done')}
    >
      <>
        <span>{t('accentro:onboardingModal:done:text')}</span>
        <div tw="flex flex-col justify-start items-start gap-px-16">
          <SimpleMuiButton
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              router.push(
                {
                  pathname: propertyOverviewUrl,
                  query: { ...propertySearchFilterValues },
                },
                undefined
              )
            }}
          >
            {t('accentro:onboardingModal:done:findPropertyNow')}
          </SimpleMuiButton>
          <Link href={`/${myAccentroBaseUrl}/profile`}>
            <SimpleMuiButton variant="text" color="primary" type="button">
              {t('accentro:myAccentro')}
            </SimpleMuiButton>
          </Link>
        </div>
      </>
    </OnboardingLayout>
  )
}
