import { useForcedLogin } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import { useEffect, useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import { useAuthYupSchema } from '../..'
import { ControlledPassword } from '../../auth/forms/ControlledPassword'
import type { ScreenProps } from '../ModalTypes'
import OnboardingLayout from './../OnboardingLayout'

export const Password = ({
  open,
  handleClose,
  nextStep,
  steps,
}: ScreenProps) => {
  type PasswordFormData = {
    newPassword: string
    confirmPassword: string
  }

  const t = useTranslation()
  const loggedIn = useForcedLogin()
  const { mutateAsync: updatePasswordMutation } =
    trpc.user.updatePassword.useMutation()
  const {
    data: getUserHasPasswordData,
    isLoading: getUserHasPasswordLoading,
    error: getUserHasPasswordError,
  } = trpc.user.getHasPassword.useQuery(undefined, {
    enabled: loggedIn,
    trpc: { ssr: false },
    staleTime: 0,
  })

  const yupSchemaResolver = useAuthYupSchema(['newPassword', 'confirmPassword'])
  const methods = useForm<PasswordFormData>({ resolver: yupSchemaResolver })
  const { control, handleSubmit } = methods
  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit = useMemo(
    () => (formData: PasswordFormData) => {
      if (
        !!(getUserHasPasswordLoading && loggedIn) ||
        !!getUserHasPasswordError
      )
        return

      updatePasswordMutation({
        newPassword: formData.newPassword,
      })
      nextStep()
    },
    [
      getUserHasPasswordLoading,
      getUserHasPasswordError,
      updatePasswordMutation,
      nextStep,
    ]
  )

  useEffect(() => {
    if (getUserHasPasswordData) nextStep()
  }, [getUserHasPasswordData, nextStep])

  const formName = 'passwordForm'
  return (
    <OnboardingLayout
      title={t('accentro:onboardingModal:password:title')}
      imageSrc="/static/images/onboarding/woman_notebook.png"
      buttons={{
        primary: {
          text: t('accentro:onboardingModal:next'),
          form: formName,
        },
        secondary: {
          text: t('accentro:onboardingModal:skip'),
          onClick: nextStep,
        },
      }}
      open={open}
      handleClose={handleClose}
      steps={steps}
    >
      <>
        <span>{t('accentro:onboardingModal:password:text')}</span>
        <FormProvider {...methods}>
          <form
            tw="flex flex-col gap-px-16"
            id={formName}
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
          >
            <fieldset
              tw="contents"
              disabled={
                !!getUserHasPasswordLoading || !!getUserHasPasswordError
              }
            >
              <ControlledPassword
                label={t('accentro:newPassword')}
                name="newPassword"
                autoFocus
                control={control}
              />
              <ControlledPassword
                label={t('accentro:repeatNewPassword')}
                name="confirmPassword"
                control={control}
              />
            </fieldset>
          </form>
        </FormProvider>
      </>
    </OnboardingLayout>
  )
}
