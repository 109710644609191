import {
  AnimateBounceRight,
  Button,
  GridWithGaps,
} from '@dreamstack/feature-components'
import type { Option } from '@dreamstack/feature-components/src/property/GroupedAutocomplete'
import { GroupedAutocomplete } from '@dreamstack/feature-components/src/property/GroupedAutocomplete'
import { ArrowRight } from '@dreamstack/icons'
// eslint-disable-next-line lodash/import-scope
import { chain } from 'lodash'
import upperFirst from 'lodash/upperFirst'
import type { FunctionComponent } from 'react'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import 'twin.macro'

type RegionCityLocation = {
  name: string
  type: string
  count: number
}

export type RegionCityLocationOption = Option<RegionCityLocation>

const PROPERTIES_EN_BASE_PATH = 'https://accentro.de/en/properties?'

export const getKey = (option: RegionCityLocationOption | string) => {
  if (typeof option === 'string') {
    return 'cities'
  } else {
    switch (option.value.type) {
      case 'location':
        return 'locations'
      case 'region':
        return 'regions'
      default:
        return 'cities'
    }
  }
}

export const getValue = (
  option: RegionCityLocationOption | string
): string | undefined => {
  return typeof option === 'string' ? option : option.value.name ?? undefined
}

const buildQueryString = (
  selectedOptions: (RegionCityLocationOption | string)[]
): string => {
  const searchParams = new URLSearchParams()
  for (const option of selectedOptions) {
    const value = getValue(option)
    if (value !== undefined) {
      searchParams.append(getKey(option), value)
    }
  }

  return searchParams.toString()
}

const useFetchRegionsCitiesLocations = () => {
  const [autocompleteCities, setAutocompleteCities] = useState<
    RegionCityLocation[]
  >([])
  useEffect(() => {
    const fetchRegionsCitiesLocations = async () => {
      const response = await fetch('/api/regions-cities-locations')
      setAutocompleteCities(await response.json())
    }
    fetchRegionsCitiesLocations()
  }, [])
  return autocompleteCities
}

export const RegionCityLocationAutocomplete = ({
  placeholder,
  selectedOptions,
  setSelectedOptions,
}: {
  placeholder: string
  selectedOptions: (RegionCityLocationOption | string)[]
  setSelectedOptions: (options: (RegionCityLocationOption | string)[]) => void
}) => {
  const [options, setOptions] = useState<RegionCityLocationOption[]>([])
  const autocompleteCities = useFetchRegionsCitiesLocations()

  useEffect(() => {
    if (autocompleteCities) {
      const options: RegionCityLocationOption[] = chain(autocompleteCities)
        .map((r) => ({
          value: r,
          optionLabel: r.name ?? '',
          optionDisplayedQuantity: r.count,
          optionGroupLabel: upperFirst(r?.type ?? ''),
        }))
        .orderBy(['optionGroupLabel', 'optionLabel'], ['desc', 'asc'])
        .value()
      setOptions(options)
    }
  }, [autocompleteCities])

  return (
    <GroupedAutocomplete
      placeholder={placeholder}
      options={options}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      uiVariant="filled"
    />
  )
}

export const FindYourProperties: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const buttonLabel = 'Find your ideal property'

  const [selectedOptions, setSelectedOptions] = React.useState<
    (RegionCityLocationOption | string)[]
  >([])

  const link = useMemo(() => {
    return PROPERTIES_EN_BASE_PATH + buildQueryString(selectedOptions)
  }, [selectedOptions])

  return (
    <GridWithGaps>
      <div tw="col-span-full">
        <div tw="flex flex-col w-full md:(flex-row items-center) justify-center">
          <div tw="mb-px-16 md:(mb-0 mr-px-48) flex-1">
            <RegionCityLocationAutocomplete
              placeholder="Where do you want to invest?"
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          </div>
          <div tw="">
            {/* <Link href={link} target="_blank"> */}
            <Button
              href={link}
              variant="contained"
              color="primary"
              endIcon={
                <AnimateBounceRight>
                  <ArrowRight />
                </AnimateBounceRight>
              }
            >
              {buttonLabel}
            </Button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </GridWithGaps>
  )
}
