import { useTranslation } from '@dreamstack/i18n'
import type { RouterOutputs } from '@dreamstack/trpc/client'
import { trpc } from '@dreamstack/trpc/client'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { useEffect, useState } from 'react'
import 'twin.macro'
import type { PersonalInfo as MyProfilePersonalInfo } from '../../my/profile/MyProfile'
import { MyProfileTextInput } from '../../my/profile/MyProfileText'
import type { ScreenProps } from '../ModalTypes'
import OnboardingLayout from './../OnboardingLayout'

export const PersonalInfo = ({
  open,
  handleClose,
  nextStep,
  steps,
  getContactQueryResult,
}: ScreenProps & {
  getContactQueryResult: {
    data?: RouterOutputs['contact']['getContact']
    isLoading: boolean
    error: any
  }
}) => {
  const t = useTranslation()
  const { mutateAsync: updateContactMutation } =
    trpc.contact.updateContact.useMutation()

  const [personalInfo, setPersonalInfo] = useState<MyProfilePersonalInfo>({
    firma: null,
    anrede: null,
    titel: null,
    vorname: null,
    name: null,
    strasse: null,
    hausnummer: null,
    plz: null,
    ort: null,
    land: null,
    tel: null,
  })
  const setValue =
    (attr: keyof MyProfilePersonalInfo) => (value: string | null) => {
      setPersonalInfo({
        ...personalInfo,
        [attr]: value,
      })
    }

  const { data, isLoading, error } = getContactQueryResult

  useEffect(() => {
    if (data) {
      const contact = data
      setPersonalInfo({
        firma: contact.company ?? null,
        anrede: contact.salutation ?? null,
        titel: contact.academic_title ?? null,
        vorname: contact.first_name ?? null,
        name: contact.last_name ?? null,
        strasse: contact.home_street ?? null,
        hausnummer: contact.home_house_number ?? null,
        plz: contact.home_zip_code ?? null,
        ort: contact.home_city ?? null,
        land: contact.home_country ?? null,
        tel: contact.tel ?? null,
      })
    }
  }, [data])

  const handleSubmit = () => {
    if (isLoading || !!error) return
    const contact = omitBy(
      {
        company: personalInfo.firma,
        salutation: personalInfo.anrede,
        academic_title: personalInfo.titel,
        first_name: personalInfo.vorname,
        last_name: personalInfo.name,
        home_street: personalInfo.strasse,
        home_house_number: personalInfo.hausnummer,
        home_zip_code: personalInfo.plz,
        home_city: personalInfo.ort,
        home_country: personalInfo.land,
        tel: personalInfo.tel,
      },
      isNil
    )
    updateContactMutation(contact)
    nextStep()
  }

  const formName = 'personalInfoForm'
  return (
    <OnboardingLayout
      canGoBack
      title={t('accentro:onboardingModal:personalInfo:title')}
      imageSrc="/static/images/onboarding/nice_door.png"
      buttons={{
        primary: {
          text: t('accentro:onboardingModal:next'),
          form: formName,
        },
        secondary: {
          text: t('accentro:onboardingModal:skip'),
          onClick: nextStep,
        },
      }}
      open={open}
      handleClose={handleClose}
      steps={steps}
    >
      <>
        <span>{t('accentro:onboardingModal:personalInfo:text')}</span>
        <form
          id={formName}
          tw="grid grid-cols-6 gap-px-16"
          onSubmit={handleSubmit}
        >
          <fieldset tw="contents" disabled={isLoading || !!error}>
            <div tw="col-span-full sm:col-span-3">
              <MyProfileTextInput
                title={t('accentro:my:myProfile:userDataSection:vorname')}
                value={personalInfo.vorname}
                setValue={setValue('vorname')}
                loading={isLoading || !!error}
              />
            </div>
            <div tw="col-span-6 sm:col-span-3">
              <MyProfileTextInput
                title={t('accentro:my:myProfile:userDataSection:name')}
                value={personalInfo.name}
                setValue={setValue('name')}
                loading={isLoading || !!error}
              />
            </div>
            <div tw="col-span-6 sm:col-span-4">
              <MyProfileTextInput
                title={t('accentro:my:myProfile:userDataSection:street')}
                value={personalInfo.strasse}
                setValue={setValue('strasse')}
                loading={isLoading || !!error}
              />
            </div>
            <div tw="col-span-6 sm:col-span-2">
              <MyProfileTextInput
                title={t('accentro:my:myProfile:userDataSection:hausnummer')}
                value={personalInfo.hausnummer}
                setValue={setValue('hausnummer')}
                loading={isLoading || !!error}
              />
            </div>
            <div tw="col-span-6 sm:col-span-2">
              <MyProfileTextInput
                title={t('accentro:my:myProfile:userDataSection:plz')}
                value={personalInfo.plz}
                setValue={setValue('plz')}
                loading={isLoading || !!error}
              />
            </div>
            <div tw="col-span-6 sm:col-span-4">
              <MyProfileTextInput
                title={t('accentro:my:myProfile:userDataSection:wohnort')}
                value={personalInfo.ort}
                setValue={setValue('ort')}
                loading={isLoading || !!error}
              />
            </div>
            <div tw="col-span-full">
              <MyProfileTextInput
                title={t('accentro:my:myProfile:userDataSection:telefon')}
                value={personalInfo.tel}
                setValue={setValue('tel')}
                loading={isLoading || !!error}
              />
            </div>
          </fieldset>
        </form>
      </>
    </OnboardingLayout>
  )
}
