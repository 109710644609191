import { useIsLoggedIn } from '@dreamstack/auth'
import { trpc } from '@dreamstack/trpc/client'
import { useEffect } from 'react'
import { useOnboardingContext } from './OnboardingContext'
import OnboardingModal from './OnboardingModal'

export const SelfManagedOnboardingModal = () => {
  const isLoggedIn = useIsLoggedIn()
    const { data: user } = trpc.user.getCurrentUser.useQuery(undefined, {
        enabled: isLoggedIn,
        trpc: { ssr: false },
        staleTime: 0,
    })

  const userAlreadyOnboarded = user?.onboarded
  const { step, setStep, setOpen, open } = useOnboardingContext()
  useEffect(() => {
    if (userAlreadyOnboarded === false) {
      setOpen(true)
    }
  }, [userAlreadyOnboarded, setOpen])

  return (
    <OnboardingModal
      open={open}
      handleClose={step === 5 ? () => setOpen(false) : () => setStep(5)}
    />
  )
}
