import type { FunctionComponent} from 'react';
import { createContext, useContext, useState } from 'react'

const useOnboardingContextState = () => {
  const [step, setStep] = useState(0)
  const [open, setOpen] = useState(false)
  return {
    step,
    setStep,
    open,
    setOpen,
  }
}

type IOnboardingContext = ReturnType<typeof useOnboardingContextState>

export const OnboardingContext = createContext({} as IOnboardingContext)

export const OnboardingContextProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const context = useOnboardingContextState()
  return (
    <OnboardingContext.Provider value={context}>
      {children}
    </OnboardingContext.Provider>
  )
}

export function useOnboardingContext() {
  return useContext(OnboardingContext)
}
