import { useIsLoggedIn } from '@dreamstack/auth'
import { trpc } from '@dreamstack/trpc/client'
import { useState } from 'react'
import 'twin.macro'
import type { PropertySearchFilterValues } from '..'
import { useOnboardingContext } from '..'
import { ConsentAndInterests } from './screens/ConsentAndInterest'
import { Done } from './screens/Done'
import { Password } from './screens/Password'
import { PersonalInfo } from './screens/PersonalInfo'
import { SearchProfile } from './screens/SearchProfile'
import { Welcome } from './screens/Welcome'

type Props = {
  open: boolean
  handleClose: () => void
}

const OnboardingModal = ({ open, handleClose }: Props) => {
  const loggedIn = useIsLoggedIn()
  const getContactQueryResult = trpc.contact.getContact.useQuery(undefined, {
    enabled: loggedIn,
    trpc: { ssr: false },
    staleTime: 0,
  })

  const { isLoading: getContactQueryLoading } = getContactQueryResult

  const { data: getUserHasPasswordData, isLoading: getUserHasPasswordLoading } =
    trpc.user.getHasPassword.useQuery(undefined, {
      enabled: loggedIn,
      trpc: { ssr: false },
      staleTime: 0,
    })

  const userHasPassword = getUserHasPasswordData

  const [propertySearchFilterValues, setPropertySearchFilterValues] = useState<
    PropertySearchFilterValues | undefined
  >(undefined)

  const { step, setStep } = useOnboardingContext()

  if (!loggedIn) return null

  // Account for missing step "Security" when the user already has a password
  const steps = {
    step: !!userHasPassword ? step - 1 : step,
    total: !!userHasPassword ? 3 : 4,
  }

  // TODO: Think about refactoring stuff to hooks, since now there is a context we can use in hooks.
  const handleNextStep = () => {
    setStep(step + 1)
  }

  if (
    (getUserHasPasswordLoading && loggedIn) ||
    (getContactQueryLoading && loggedIn)
  )
    return <></>

  switch (step) {
    case 0:
      return (
        <Welcome
          open={open}
          handleClose={handleClose}
          nextStep={handleNextStep}
        />
      )
    case 1:
      return (
        <Password
          open={open}
          handleClose={handleClose}
          nextStep={handleNextStep}
          steps={steps}
        />
      )
    case 2:
      return (
        <PersonalInfo
          open={open}
          handleClose={handleClose}
          nextStep={handleNextStep}
          steps={steps}
          getContactQueryResult={{
            data: getContactQueryResult.data,
            isLoading: getContactQueryLoading,
            error: getContactQueryResult.error,
          }}
        />
      )
    case 3:
      return (
        <ConsentAndInterests
          open={open}
          handleClose={handleClose}
          nextStep={handleNextStep}
          steps={steps}
          getContactQueryResult={{
            data: getContactQueryResult.data,
            isLoading: getContactQueryLoading,
            error: getContactQueryResult.error,
          }}
        />
      )
    case 4:
      return (
        <SearchProfile
          open={open}
          handleClose={handleClose}
          nextStep={handleNextStep}
          steps={steps}
          setPropertySearchFilterValues={setPropertySearchFilterValues}
        />
      )
    case 5:
      return (
        <Done
          open={open}
          handleClose={handleClose}
          nextStep={handleNextStep}
          propertySearchFilterValues={propertySearchFilterValues}
        />
      )
  }

  return <></>
}

export default OnboardingModal
