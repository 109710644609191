import { useTranslation } from '@dreamstack/i18n'
import { useEffect, useRef, useState } from 'react'
import 'twin.macro'
import { DialogWithImage } from '../dialog/DialogWithImage'
import { SimpleMuiButton } from '../layout/SimpleMuiButton'
import { useGoBack } from './useGoBack'

type ButtonProps = {
  text: string
} & ({ onClick: () => void; form?: never } | { onClick?: never; form: string })

type Props = {
  open: boolean
  handleClose: () => void
  title: string
  subtitle?: string
  steps?:
    | {
        step: number
        total: number
      }
    | string
  children: React.ReactNode
  buttons?: {
    primary: ButtonProps
    secondary?: {
      text: string
      onClick: () => void
    }
  }
  canGoBack?: boolean
  imageSrc: string
}

const OnboardingLayout = ({
  open,
  handleClose,
  title,
  subtitle,
  steps,
  children,
  buttons,
  imageSrc,
  canGoBack,
}: Props) => {
  const t = useTranslation()
  const [biggerThanVP, setBiggerThanVP] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)
  const goBack = useGoBack()

  useEffect(() => {
    const handleResize = () => {
      if (
        modalRef.current !== null &&
        modalRef.current.clientHeight > window.innerHeight
      ) {
        setBiggerThanVP(true)
      } else {
        setBiggerThanVP(false)
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <DialogWithImage
      open={open}
      handleClose={handleClose}
      imageSrc={imageSrc}
      title={title}
      subtitle={subtitle}
      steps={steps}
    >
      {children}
      {!!buttons && (
        <div tw="flex gap-px-16">
          {canGoBack && (
            <SimpleMuiButton
              color="primary"
              variant="outlined"
              onClick={() => goBack()}
            >
              {t('accentro:onboardingModal.goBack')}
            </SimpleMuiButton>
          )}
          <SimpleMuiButton
            variant="contained"
            color="primary"
            onClick={
              'onClick' in buttons.primary ? buttons.primary.onClick : undefined
            }
            type="submit"
            form={'form' in buttons.primary ? buttons.primary.form : undefined}
          >
            {buttons.primary.text}
          </SimpleMuiButton>
          {!!buttons.secondary && (
            <SimpleMuiButton
              tw="pl-2 pr-2"
              variant="text"
              color="primary"
              size="small"
              type="button"
              onClick={buttons.secondary.onClick}
            >
              <span tw="p-px-20">{buttons.secondary.text}</span>
            </SimpleMuiButton>
          )}
        </div>
      )}
    </DialogWithImage>
  )
}

export default OnboardingLayout
