import { useTranslation } from '@dreamstack/i18n'
import { SimpleSwitch } from '@dreamstack/simple-components'
import type { RouterOutputs } from '@dreamstack/trpc/client'
import { trpc } from '@dreamstack/trpc/client'
import { useEffect, useState } from 'react'
import 'twin.macro'
import type { ScreenProps } from '../ModalTypes'
import OnboardingLayout from '../OnboardingLayout'

export const ConsentAndInterests = ({
  open,
  handleClose,
  nextStep,
  steps,
  getContactQueryResult,
}: ScreenProps & {
  getContactQueryResult: {
    data?: RouterOutputs['contact']['getContact']
    isLoading: boolean
    error: any
  }
}) => {
  const t = useTranslation()
  const [newsletter, setNewsletter] = useState<boolean>(false)
  const [accept_contact, setAcceptContact] = useState<boolean>(false)
  const { mutateAsync: updateContact } =
    trpc.contact.updateContact.useMutation()

  const { data, isLoading, error } = getContactQueryResult

  useEffect(() => {
    if (data) {
      setNewsletter(!!data.newsletter)
      setAcceptContact(!!data.accept_contact)
    }
  }, [data, setNewsletter, setAcceptContact])

  const formName = 'consentAndInterestForm'

  const handleSubmit = () => {
    if (isLoading || !!error) return
    updateContact({
      newsletter,
      accept_contact,
    })
    nextStep()
  }

  return (
    <OnboardingLayout
      canGoBack
      title={t('accentro:onboardingModal:consentAndInterest:title')}
      imageSrc="/static/images/onboarding/Accentro_Gang.png"
      buttons={{
        primary: {
          text: t('accentro:onboardingModal:next'),
          form: formName,
        },
        secondary: {
          text: t('accentro:onboardingModal:skip'),
          onClick: nextStep,
        },
      }}
      open={open}
      handleClose={handleClose}
      steps={steps}
    >
      <span>{t('accentro:onboardingModal:consentAndInterest:text')}</span>
      <form id={formName} onSubmit={handleSubmit}>
        <div tw="flex flex-col gap-px-16">
          <SimpleSwitch
            label={t('accentro:newsletter')}
            description={t(
              'accentro:onboardingModal:consentAndInterest:newsletterDescription'
            )}
            grayDescription={true}
            checked={newsletter}
            setChecked={setNewsletter}
            disabled={false}
          />
          <SimpleSwitch
            label={t('accentro:my:myProfile:interestSection:kontakterlaubnis')}
            description={t(
              'accentro:my:myProfile:interestSection:kontakterlaubnisText'
            )}
            grayDescription={true}
            checked={accept_contact}
            setChecked={setAcceptContact}
            disabled={false}
          />
        </div>
      </form>
    </OnboardingLayout>
  )
}
